/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import Header from "../components/header";
/* ------------------------------- // Packages ------------------------------ */
// import * as React from "react";
import React, { useState, useEffect,useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import "./style.scss";
import Img from "gatsby-image";


// import '@bentoproject/fit-text/styles.css';
// import {BentoFitText} from '@bentoproject/fit-text/react';

/* ----------------------------- // Local Images ---------------------------- */
import upArrow from "../images/upArrow.png";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */
var a; 
for (a=0;a<4;a++){
    console.log();
}
function ReadMore({location, data}) {
    const params = new URLSearchParams(location.search);
    const lang= params.get("lang");
    const readMore = {
        "en":{
            "first":"Why are you in such a hurry? We're still working on the website!! (ง•_•)ง",
            "second":"Click me to go back ↰",
            "third":"Stay here ψ(._. )>",
            "forth":"NO! go there ※╰（‵□′）╯"
        },
        "ar":{
            "first":"Chbik Mazroub(a)? mezelna nekhdmou fel Site, stanna chwaya (ง•_•)ง",
            "second":"Arja3 Mnin jit ↰",
            "third":"o93ed ψ(._. )>",
            "forth":"barrwa7 ※╰（‵□′）╯"
        }        
    };  // }
    return (
        <>
            <GoBack>
                
                <Link href="/" className="goBack">
                    {"goback"}
                </Link> 

            </GoBack>
            wawa
            <img src="/static/1.png" alt="sad"/>
            <Filler />
        </>
    );
    
}
export default ReadMore;
export const Head = () => (
    <>
        <title>Why The Hurry</title>
    </>
);
/* ---------------------------- Styled Components --------------------------- */
const GoBack = styled.h3`
    color: #000;
    text-decoration: none;
    .goBack {
        color: #3259b4;
        text-decoration: none;
        transition: color 1s;
        &:hover {
            color: #829fe1;
            cursor: cell;
        }
    }
    #burger {
        width: 50px;
    }
`;
const Wa= styled.img`
    width:200px;
    height: auto;
`;
const Filler = styled.div`
    width: 100%;
    height: 1700px;
    background-color: #fff;
`;
/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */